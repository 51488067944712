"use client"

import { Button, paragraph, title } from "@dalmatech/ui"
import { RefreshCw } from "lucide-react"
import React from "react"

// IMPORTANT: THIS IS A TEMPORARY PAGE! WE WIIL ADD THE REAL ERROR PAGE LATER
export default function GlobalError() {
  const redirect = () => {
    // Make a hard refresh to clear the cache
    window.location.href = "/"
  }

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center justify-center gap-2 rounded-lg bg-blurple-50 px-4 py-6 text-center">
      <span
        className={title({
          size: "lg",
          className: "text-blurple-500",
        })}
      >
        Something went wrong
      </span>
      <span
        className={paragraph({
          size: "md",
        })}
      >
        We are investigating the issue. Please try again in a few minutes.
      </span>

      <Button className="mt-4" onClick={redirect} RightIcon={<RefreshCw className="w-4" />}>
        Try again
      </Button>
    </div>
  )
}
